
.login-container{
    background-position: initial !important;
    background-size: contain !important;
}

.login-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}



.forgotPassword:hover{
    cursor: pointer;
    border-bottom: 1px solid grey;
    width: fit-content;
}

